<template>
  <v-container>
    <template v-if="!carregando">
      <v-row no-gutters>
        <v-col cols="12" sm="6" md="9">
          <h1 class="mb-1 text-h5">{{ categoria }}</h1>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-if="produtos.length && !this.top20"
            :items="['Favoritos', 'Menor Preço', 'Maior Preço']"
            label="Ordenar por"
            v-model="ordem"
            @input="mudaOrdem"
            hide-details
          />
        </v-col>
      </v-row>

      <listagem-produtos :produtos="produtos" />

      <v-pagination
        v-if="totalPaginas > 1"
        v-model="pagina"
        class="mt-4"
        :length="totalPaginas"
        @input="buscarProdutos"
      />
    </template>
    <div v-else :style="`height: ${alturaPagina}px`">
      <v-progress-circular
        color="primary"
        indeterminate
        size="128"
        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
      />
    </div>
  </v-container>
</template>

<script>
import ListagemProdutos from "../components/ListagemProdutos.vue";
import ProdutoRepository from "@/repositories/ProdutoRepository";

export default {
  name: "Home",

  components: { ListagemProdutos },

  props: {
    idCategoria: String,
    termoBusca: String
  },

  data: () => ({
    produtos: [],
    ordem: "Favoritos",
    categoria: "",
    pagina: 1,
    totalPaginas: 1,
    carregando: true
  }),

  async created() {
    if (this.top20) {
      this.ordem = "Maior Preço";
    }

    this.buscarProdutos();
  },

  computed: {
    top20() {
      return this.idCategoria == "sex-shop_produtos-eroticos-mais-vendidos";
    },

    alturaPagina() {
      return window.innerHeight - 112 - 75;
    }
  },

  methods: {
    async buscarProdutos() {
      this.carregando = true;
      const metodoBusca = this.idCategoria
        ? ProdutoRepository.buscarPorCategoria
        : ProdutoRepository.buscarPorTermo;

      [this.categoria, this.produtos, this.totalPaginas] = await metodoBusca.call(
        ProdutoRepository,
        this.idCategoria || this.termoBusca,
        this.pagina,
        this.ordem
      );

      this.carregando = false;
      window.scrollTo({ top: 0 });
    },

    mudaOrdem() {
      this.pagina = 1;
      this.buscarProdutos();
    }
  },

  metaInfo() {
    return {
      title: `Erótica BH - ${this.categoria}`,
      meta: [
        {
          name: "description",
          content: `Aqui você encontra os produtos da categoria ${this.categoria}.`
        }
      ]
    };
  }
};
</script>
