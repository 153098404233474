<template>
  <v-row>
    <template v-if="produtos.length">
      <v-col
        cols="12"
        sm="6"
        md="3"
        style="padding: 6px;"
        v-for="produto in produtos"
        v-bind:key="produto.id"
      >
        <card-produto :produto="produto" />
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12">
        Nenhum produto encontrado, tente encontra-lo pelas categorias abaixo:
        <v-expansion-panels accordion class="mt-5">
          <v-expansion-panel v-for="categoria in categorias" :key="categoria.link">
            <v-expansion-panel-header>{{ categoria.label }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="12" md="3">
                  <v-list-item tag="a" :href="`/#/categoria/${categoria.link}`">
                    Ver todos
                  </v-list-item>
                </v-col>
                <v-col
                  v-for="subcategoria in categoria.sub"
                  :key="subcategoria.link"
                  cols="12"
                  md="3"
                >
                  <v-list-item tag="a" :href="`/#/categoria/${subcategoria.link}`">
                    {{ subcategoria.label }}
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import CategoriaRepository from "@/repositories/CategoriaRepository";
import CardProduto from "./CardProduto.vue";

export default {
  components: { CardProduto },

  props: {
    produtos: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    categorias: []
  }),

  async created() {
    if (!this.produtos.length) {
      const categorias = await CategoriaRepository.buscarTodas(true);
      this.categorias = categorias.filter(categoria =>
        ["sex-shop", "sex-shop_vibradores", "lingeries-sensuais"].includes(categoria.link)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.col-6 {
  @media only screen and (max-width: 959px) {
    padding: 6px !important;
  }
}
</style>
