<template>
  <v-card
    elevation="1"
    :class="{ 'pa-4': isDesktop, 'pb-2': !isDesktop }"
    tag="a"
    :href="`/#/produto/${produto.rota}`"
  >
    <v-row no-gutters>
      <v-col cols="5" sm="12">
        <div class="box-img justify-center align-center">
          <v-img
            :alt="produto.nome"
            contain
            :src="`${produto.imagens.medium[0]}`"
            transition="scale-transition"
            :max-height="isDesktop ? 200 : 156"
          />
        </div>
      </v-col>
      <v-col cols="7" sm="12">
        <div class="d-flex justify-center px-2" :class="{ 'mt-2': isDesktop, 'mt-4': !isDesktop }">
          <h2 class="subtitle-1">{{ produto.nome }}</h2>
        </div>
        <div class="d-flex justify-center mt-2 px-2" v-if="produto.preco">
          <span v-if="produto.estoque < 1" style="padding-bottom: 12px;">
            R$ {{ produto.preco.toFixed(2).replace(".", ",") }} -
            <span class="red--text">Indisponível</span>
          </span>
          <span v-else class="preco green--text">
            R$ {{ produto.preco.toFixed(2).replace(".", ",") }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    produto: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card:hover {
  opacity: 0.75;
}

.box-img {
  background-color: white;
  height: 200px;
  @media only screen and (max-width: 959px) {
    height: 156px;
  }
}

h2.subtitle-1 {
  line-height: 1.1 !important;
  height: 70px;
  @media only screen and (max-width: 959px) {
    height: 90px;
  }
}

.preco {
  font-size: 24px;
  font-weight: bold;
}
</style>
