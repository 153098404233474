import api from "@/api";
import normalizaRota from "../utils/normalizaRota";
import { setWithExpiry, getWithExpiry, getCookie } from "../utils/storage";

const porPagina = 20;
let controller;

class ProdutoRepository {
  async totalPorCategoria(urlCategoria) {
    const { data } = await api.get("/", {
      params: {
        route: "category_products",
        url: "/" + urlCategoria.split("_").join("/") + "/",
        offset: 0,
        limit: 1,
        order: "Popularidade",
        afiliacao: getCookie("afiliacao") ? 1 : 0
      }
    });
    console.log(urlCategoria);
    console.log(data);
    return data.info.total;
  }

  async buscarPorCategoria(urlCategoria, pagina, ordem) {
    let type = urlCategoria.substring(0, 7) == "marcas_" ? "brand" : "category";

    const { data } = await api.get("/", {
      params: {
        route: type + "_products",
        url: "/" + urlCategoria.split("_").join("/") + "/",
        offset: (pagina - 1) * porPagina,
        limit: porPagina,
        order: ordem,
        afiliacao: getCookie("afiliacao") ? 1 : 0
      }
    });

    const produtos = data.produtos.map(produto => this.#formataProduto(produto));

    setWithExpiry("produtos", JSON.stringify(produtos), 10);

    return [data.info.nome, produtos, Math.ceil(data.info.total / porPagina)];
  }

  async buscarPorRota(rota) {
    const jsonProdutos = getWithExpiry("produtos");

    if (jsonProdutos) {
      const produto = JSON.parse(jsonProdutos).find(produto => produto.rota === rota);

      if (produto) {
        return produto;
      }
    }

    const { data } = await api.get("/", {
      params: {
        route: "product",
        url: "/" + rota.split("_").join("/") + "/",
        afiliacao: getCookie("afiliacao") ? 1 : 0
      }
    });

    return this.#formataProduto(data);
  }

  async buscarPorTermo(termo, pagina = 1, ordem = null) {
    if (controller) {
      controller.abort();
    }

    let params = {
      route: "products_search",
      busca: termo,
      offset: (pagina - 1) * porPagina,
      limit: ordem === null ? 5 : porPagina,
      afiliacao: getCookie("afiliacao") ? 1 : 0
    };

    if (ordem !== null) {
      params.order = ordem;
    }

    controller = new AbortController();
    const { data } = await api.get("/", { params, signal: controller.signal });

    let produtos = [];
    if (Object.keys(data).length) {
      produtos = data.produtos.map(produto => this.#formataProduto(produto));
    }

    return ordem
      ? [`Resultados para ${termo}`, produtos, Math.ceil(data.info.total / params.limit)]
      : produtos;
  }

  #formataProduto(produto) {
    return {
      ...produto,
      rota: normalizaRota(produto.rota),
      preco: produto.precos ? produto.precos.por : null,
      produtosRelacionados: produto.produtosRelacionados.map(produto =>
        this.#formataProduto(produto)
      )
    };
  }
}

export default new ProdutoRepository();
